import React from 'react';

/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { Disclosure } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { Link } from "gatsby"

const navigation = [
  { name: 'Home', href: '/' },
  { name: 'Truck Accidents', href: '/truck-accidents' },
  { name: 'Other Practice Areas', href: 'https://www.bartlettlegalgroup.com' },
  { name: 'Results', href: '/results' },
  { name: 'About', href: '/about' },
]

const Header = () => {
  return (
    <Disclosure as="nav" className="bg-gray-900">
      {({ open }) => (
        <>
          <div className="container mx-auto px-2">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-white hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center pt-2 md:pt-0 sm:items-stretch sm:justify-start">
                <div className="flex-shrink-0 flex items-center">
                  <Link className="logo text-white text-4xl" to="/">
                    <span
                        className="logo-span-flip">B
                    </span>
                    <span>B</span>                    
                  </Link>
                </div>
                <div className="hidden lg:flex my-auto sm:ml-1">
                    <div className="space-x-4">
                        <span className="ml-2 text-white logo text-2xl xl:text-3xl">Bartlett Legal Group</span>
                    </div>
                    
                </div>
                <div className="hidden sm:flex m-auto sm:ml-6">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <Link
                        to={item.href}
                        key={item.name}
                        activeClassName="bg-gray-700 text-white px-3 py-2 rounded-md text-base font-medium"
                        className="hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-white font-medium"
                      >{item.name}</Link>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  activeClassName="bg-gray-700 text-white block px-3 py-2 rounded-md text-base font-medium"
                  className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}


export default Header;