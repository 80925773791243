import React from 'react';


const Footer = ()=>{
    return(
        <footer className="bg-gray-900">
            <div className="container mx-auto md:p-8 p-4 text-white">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-32">
                    <div className="text-left align-left">
                        <p className="mb-4"><strong>Find us on social media:</strong></p>
                        <div className="flex items-center justify-left space-x-3">
                            <button className="bg-blue-500 px-4 py-2 font-semibold text-white inline-flex items-center space-x-2 rounded">
                                <svg className="w-5 h-5 fill-current" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"></path></svg>
                                <span>Facebook</span>
                            </button>
                            <button className="bg-blue-300 px-4 py-2 font-semibold text-white inline-flex items-center space-x-2 rounded">
                                <svg className="w-5 h-5 fill-current" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"></path></svg>
                                <span>@BartlettLegal</span>
                            </button>
                        </div>
                        <div className="text-green-400 mt-8">
                            <a href="tel:1-203-463-4939">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                                </svg>
                                <div className="ml-2 inline">Call us @ 203-463-4939</div>
                            </a>
                        </div>
                    </div>
                    <div>
                        <em><strong>Bartlett Legal Group serves the following areas: </strong></em>Our firm takes referrals from across the country representing clients throughout Connecticut, including the following counties, New Haven, Fairfield, Hartford, Middlesex, New London, Tolland, Litchfield, and Windham. We are centrally located in Cheshire, CT and serve clients from the following cities on a regular basis. Bridgeport, Stamford, Waterbury, Norwalk, Danbury, New Britain, Meriden, and Bristol.
                        <p className="mt-4"><strong>Disclaimer</strong></p>
                        <p>Any use of badges and awards on this website is not intended to suggest that our attorneys are superior to other attorneys practicing in Connecticut.</p>
                        <p>If you have any website concerns or suggestions please feel free to email  webmaster@bartlettlegalgroup.com</p>
                    </div>
                </div>
            </div>
        </footer>
    )
};

export default Footer;